import React from "react";

const BookmarkIcon: React.FunctionComponent<React.SVGProps<any>> = ({
  fill = "#FFF",
  stroke = "#A5ADBA",
  ...props
}) => (
  <svg viewBox="0 0 12 18" width={12} height={18} {...props}>
    <path
      d="M11.47 17.205l-5.345-5.452a.175.175 0 00-.25 0L.53 17.205V.54h10.942v16.665z"
      fillRule="nonzero"
      fill={fill}
      stroke={stroke}
    />
  </svg>
);

export default BookmarkIcon;
