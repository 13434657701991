export default function getTargetType(entityType: string): string {
  if (["VendorUser", "GovernmentUser", "PendingUser"].includes(entityType)) {
    return "USER";
  } else if (entityType === "Company") {
    return "COMPANY";
  } else if (entityType === "Government") {
    return "GOVERNMENT";
  } else {
    // should never happen... but ¯\_(ツ)_/¯
    throw new Error("Invalid entityType provided");
  }
}
