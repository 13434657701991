import { useRouter } from "next/router";
import React from "react";
import stringOrFirst from "~/src/utils/stringOrFirst";
import HeaderBanner from "./HeaderBanner";
import { headerBannerExists } from "./headerBannerExists";
import LandingPageGrid from "./LandingPageGrid";
import GetActiveLandingPageConfig from "./graphql/GetActiveLandingPageConfig";
import {
  IGetActiveLandingPageConfigQuery,
  IGetActiveLandingPageConfigQueryVariables,
} from "./graphql/__generated__/GetActiveLandingPageConfig.generated";
import { useQuery } from "@apollo/client";
import { LoadingSpinner } from "@govlaunch/web";
import { IHeaderBannerContent, ILandingPageConfigBlock } from "~/types/types";
import { useSelfie } from "~/components/auth/Auth";

export default function LandingPageInner() {
  const router = useRouter();
  const previewConfigId = stringOrFirst(router.query.configId);

  const { data, loading } = useQuery<IGetActiveLandingPageConfigQuery, IGetActiveLandingPageConfigQueryVariables>(
    GetActiveLandingPageConfig,
    {
      variables: {
        id: previewConfigId || null,
      },
      fetchPolicy: "network-only",
    },
  );

  if (!data || loading || !data.getActiveLandingPageConfig) {
    return <LoadingSpinner />;
  }

  return <LandingPageInnerContent config={data.getActiveLandingPageConfig} />;
}

interface IConfig {
  allowOverlap?: boolean | null;
  headerBanner: IHeaderBannerContent | null | undefined;
  blocks: ILandingPageConfigBlock[];
}

interface IConfigProps {
  config: IConfig;
}

function LandingPageInnerContent({ config }: IConfigProps) {
  const user = useSelfie();

  return (
    <div>
      {!user && config.headerBanner && headerBannerExists(config.headerBanner) && (
        <HeaderBanner data={config.headerBanner} />
      )}
      <LandingPageGrid blocks={config.blocks} allowOverlap={config.allowOverlap} />
    </div>
  );
}
