import { useQuery } from "@apollo/client";
import { Anchor, Box, Flex, Text } from "@govlaunch/web";
import moment from "moment";
import React from "react";
import GetUpcomingEvents from "../graphql/GetUpcomingEvents";
import {
  IGetUpcomingEventsQuery,
  IGetUpcomingEventsQueryVariables,
} from "../graphql/__generated__/GetUpcomingEvents.generated";
import { IBlockLayoutDataBase } from "./blockProps";
import CenteredLoadingSpinner from "./CenteredLoadingSpinner";

interface IEventsBlockProps {
  blockData: IBlockLayoutDataBase;
}

export default function EventsBlock({ blockData }: IEventsBlockProps) {
  const { data, loading } = useQuery<IGetUpcomingEventsQuery, IGetUpcomingEventsQueryVariables>(GetUpcomingEvents, {
    variables: {
      limit: blockData.itemLimit || 10,
    },
    fetchPolicy: "network-only",
  });

  if (!data || loading) {
    return <CenteredLoadingSpinner />;
  }

  const events = data.getUpcomingEvents && data.getUpcomingEvents.length > 0 ? data.getUpcomingEvents : [];

  return (
    <Box width="100%">
      {(events || []).map((event) => (
        <EventBlockRow key={event._id} event={event} />
      ))}
    </Box>
  );
}

interface IEventBlockRowProps {
  event: IGetUpcomingEventsQuery["getUpcomingEvents"][0];
}

function EventBlockRow({ event }: IEventBlockRowProps) {
  return (
    <Flex
      width="100%"
      direction={["column", "column", "column", "row"]}
      alignItems={["left", "left", "left", "center"]}
      py={["20px", "20px", "20px", "0px"]}
      minHeight="80px"
      overflow="hidden"
      borderBottom="1px solid"
      borderBottomColor="gray.100"
    >
      <Text
        width={["100%", "100%", "100%", "25%"]}
        color="gray.600"
        lineHeight="24px"
        fontWeight={[400, 400, 400, 600]}
        fontSize="16px"
      >
        {moment(event.startDate).format("MMM Do, YYYY")}
      </Text>
      <Anchor width={["100%", "100%", "100%", "50%"]} href={event.url} external="outside-redirect">
        <Text
          lineHeight={["21.3px", "21.3px", "21.3px", "24px"]}
          fontWeight={600}
          fontSize="16px"
          px={["0px", "0px", "0px", "8px"]}
          py={["10px", "10px", "10px", "0px"]}
        >
          {event.title}
        </Text>
      </Anchor>
      <Text
        width={["100%", "100%", "100%", "25%"]}
        color="gray.600"
        lineHeight={["21.3px", "21.3px", "21.3px", "24px"]}
        fontWeight={[400, 400, 400, 600]}
        fontSize="16px"
      >
        {event.location}
      </Text>
    </Flex>
  );
}
