import gql from "graphql-tag";

export default gql`
  fragment LandingPageEventFields on LandingPageEvent {
    _id
    title
    url
    location
    createdAt
    startDate
    endDate
  }
`;
