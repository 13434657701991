import React from "react";

export default function ProjectIcon(props: React.SVGProps<any>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="4" fill="#2F80ED" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0034 20.2993L13.6167 23.3335H18.3834L19.9967 20.2993C21.0234 19.6399 21.8674 18.7323 22.4506 17.6605C23.0338 16.5886 23.3374 15.3871 23.3334 14.1668C23.3334 12.2219 22.5607 10.3566 21.1855 8.98138C19.8102 7.60611 17.945 6.8335 16 6.8335C14.0551 6.8335 12.1898 7.60611 10.8146 8.98138C9.43931 10.3566 8.6667 12.2219 8.6667 14.1668C8.66268 15.3871 8.9663 16.5886 9.5495 17.6605C10.1327 18.7323 10.9766 19.6399 12.0034 20.2993ZM18.6125 18.9977L17.2834 21.5002H14.7167L13.3875 18.9885L13.1675 18.8602C12.3569 18.3732 11.6856 17.6854 11.2183 16.8633C10.7511 16.0412 10.5037 15.1125 10.5 14.1668C10.5 12.7081 11.0795 11.3092 12.1109 10.2777C13.1424 9.24629 14.5413 8.66683 16 8.66683C17.4587 8.66683 18.8577 9.24629 19.8891 10.2777C20.9206 11.3092 21.5 12.7081 21.5 14.1668C21.498 15.114 21.2513 16.0446 20.784 16.8685C20.3166 17.6923 19.6444 18.3815 18.8325 18.8693L18.6125 18.9977ZM19.6667 13.2502H16.9167V10.5002H15.0833V13.2502H12.3333V15.0835H15.0833V17.8335H16.9167V15.0835H19.6667V13.2502ZM18.75 24.2502H13.25V26.0835H18.75V24.2502Z"
        fill="white"
      />
    </svg>
  );
}
