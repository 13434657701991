import { Box } from "@govlaunch/web";
import React from "react";
import { IPodcastBlockData } from "~/types/types";
import { parse } from "node-html-parser";

interface IPodcastBlockProps {
  blockData: IPodcastBlockData;
}

export default function PodcastBlock({ blockData }: IPodcastBlockProps) {
  return (
    <Box width="100%" height="100%">
      <iframe
        src={extractSrc(blockData.podcastSrc)}
        frameBorder="0"
        css={{
          width: "100%",
          height: "100%",
        }}
      />
    </Box>
  );
}

export function extractSrc(value: string) {
  const code = parse(value);
  const iframe = code.querySelector("iframe");

  if (!iframe) {
    return value;
  }

  const iframeSrc = iframe.getAttribute("src");

  if (!iframeSrc || !iframeSrc.includes("player.simplecast.com")) {
    return "";
  }

  return iframeSrc;
}
