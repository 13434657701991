import gql from "graphql-tag";

export default gql`
  fragment LandingPageConfigFields on LandingPageConfig {
    _id
    name
    active
    createdAt
    createdBy {
      ... on PendingUser {
        _id
        fullName
      }
      ... on VendorUser {
        _id
        fullName
      }
      ... on GovernmentUser {
        _id
        fullName
      }
    }
    deactivatedAt
    deactivatedBy
    updatedAt
    allowOverlap
    headerBanner {
      title
      titleColor
      subtitle
      subtitleColor
      backgroundImage {
        url
        alt
      }
      foregroundImage {
        url
        alt
      }
      leftButtonText
      leftButtonHoverColor
      leftButtonBorderColor
      leftButtonBackgroundColor
      leftButtonTextColor
      leftButtonHref
      rightButtonText
      rightButtonHoverColor
      rightButtonBorderColor
      rightButtonBackgroundColor
      rightButtonTextColor
      rightButtonHref
    }
    blocks {
      i
      x
      y
      w
      h
      static
      isDraggable
      isResizable
      isBounded
      blockData {
        type
        title
        titleVisible
        itemLimit
        backgroundColor
        paddingTop
        paddingBottom
        paddingLeft
        paddingRight
        ... on ImageBlockData {
          url
          alt
        }
        ... on ImageLinkBlockData {
          url
          alt
          href
        }
        ... on FeaturedStoriesBlockData {
          itemLimit
          storyIds
        }
        ... on StatCountersBlockData {
          labelsColor
          countersColor
          widgetColor
          showProjects
          showProducts
          showGovernments
          showStories
          showVendors
        }
        ... on PodcastBlockData {
          podcastSrc
        }
        ... on GovernmentsBlockData {
          secondTitle
        }
        ... on CollectionsBlockData {
          secondTitle
          displayAsTags
        }
      }
    }
  }
`;
