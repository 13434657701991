import React from "react";

export default function GovernmentIcon(props: React.SVGProps<any>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="4" fill="#2F80ED" />
      <path
        d="M23.3333 22.3529H22.4167V15.5592H23.3333C23.7284 15.5592 24.0777 15.2923 24.2023 14.8953C24.3279 14.4993 24.1987 14.0626 23.8824 13.8122L16.5491 7.98902C16.2237 7.72892 15.7754 7.72892 15.45 7.98902L8.11667 13.8122C7.80133 14.0626 7.67208 14.4993 7.79767 14.8953C7.92233 15.2923 8.27158 15.5592 8.66667 15.5592H9.58333V22.3529H8.66667C8.15975 22.3529 7.75 22.7867 7.75 23.3234C7.75 23.8601 8.15975 24.2939 8.66667 24.2939H23.3333C23.8403 24.2939 24.25 23.8601 24.25 23.3234C24.25 22.7867 23.8403 22.3529 23.3333 22.3529ZM16 9.97861L20.5833 13.6181H11.4167L16 9.97861ZM11.4167 22.3529V15.5592H13.25V22.3529H11.4167ZM15.0833 22.3529V15.5592H16.9167V22.3529H15.0833ZM18.75 22.3529V15.5592H20.5833V22.3529H18.75Z"
        fill="white"
      />
    </svg>
  );
}
