import { keyframes } from "@emotion/react";
import { palette } from "@govlaunch/web";
import React, { useEffect, useState } from "react";
import useIsMobile from "~/lib/hooks/useIsMobile";

export default function BackToTop() {
  const isMobile = useIsMobile();
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);

    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [showScroll]);

  return (
    <div
      aria-hidden={showScroll === false}
      style={{
        display: showScroll ? "flex" : "none",
      }}
      css={{
        position: "fixed",
        right: 24,
        bottom: process.env.NODE_ENV === "production" || isMobile ? 160 : 96,
        zIndex: 9999,
        cursor: "pointer",
      }}
    >
      <button
        type="button"
        onClick={scrollTop}
        tabIndex={-1}
        aria-label="Back to top"
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 48,
          height: 48,
          margin: 0,
          padding: 0,
          color: palette.white,
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          border: "none",
          borderRadius: "100%",
          outline: "none",
          cursor: "pointer",
          appearance: "none",
          userSelect: "none",
          "&:hover": {
            backgroundColor: palette.blue[500],
            boxShadow: "0 1px 8px 0 #33333366",
            transition: "box-shadow 60ms ease 0s",
            animation: `${wiggle} 1.2s ease`,
            animationIterationCount: 1,
          },
          WebkitAppearance: "none",
        }}
      >
        <ArrowUp width={32} height={32} display="flex" />
      </button>
    </div>
  );
}

const ArrowUp = (props: React.SVGProps<any>) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M12 19V5M5 12l7-7 7 7" />
    </svg>
  );
};

const wiggle = keyframes`
  20% {
    transform: translateY(6px);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(4px);
  }
  80% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
`;
