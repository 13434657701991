import { AnchorButton, Box, Flex, Heading, Image, PageGridContainer } from "@govlaunch/web";
import React from "react";
import { IHeaderBannerContent } from "~/types/types";
import Link from "next/link";

interface IHeaderBannerProps {
  data: IHeaderBannerContent;
}

export default function HeaderBanner({ data }: IHeaderBannerProps) {
  return (
    <Box position="relative" pt="30px" pb="30px">
      {data.backgroundImage && data.backgroundImage.url && (
        <Image
          ignoreFallback={true}
          alt={data.backgroundImage.alt || ""}
          src={data.backgroundImage.url}
          width="100%"
          height="100%"
          objectFit="cover"
          position="absolute"
          left={0}
          top={0}
        />
      )}

      <PageGridContainer paddingLeft="0.5rem" paddingRight="0.5rem">
        <Box
          position="relative"
          textAlign={["center", "center", "center", "left"]}
          minHeight="350px"
          display="flex"
          flexDirection={["column", "column", "column", "row"]}
          alignItems={["center", "center", "center", "center"]}
        >
          <Box position="relative" zIndex={99} maxWidth={["100%", "100%", "100%", "50%"]}>
            <Heading
              as="h1"
              fontSize={["32px", "32px", "55px"]}
              color={data.titleColor || "#ffffff"}
              maxWidth={["auto", "auto", "auto", "770px"]}
              lineHeight={["38px", "38px", "60px"]}
            >
              {data.title}
              <sup
                css={{
                  margin: 0,
                  padding: 0,
                  fontSize: 32,
                  "@media (max-width: 768px)": {
                    top: "-0.25em",
                  },
                }}
              >
                ®
              </sup>
            </Heading>
            {data.subtitle && (
              <Heading
                mt={["24px", "24px", "24px", "8px"]}
                as="h2"
                fontWeight={["bold", "bold", "normal"]}
                fontSize={["18px", "18px", "28px"]}
                color={data.subtitleColor || "#ffffff"}
                maxWidth={["auto", "auto", "auto", "610px"]}
              >
                {data.subtitle}
              </Heading>
            )}
            {(data.leftButtonText || data.rightButtonText) && (
              <Flex
                display="flex"
                wrap="nowrap"
                direction={["column", "column", "row", "row"]}
                align={["center", "center", "center", "unset"]}
                mt={["40px", "40px", "40px", "40px"]}
                order={[2, 2, 2, 1]}
              >
                {data.leftButtonText && (
                  <HeaderBannerButton
                    text={data.leftButtonText}
                    href={data.leftButtonHref}
                    backgroundColor={data.leftButtonBackgroundColor}
                    textColor={data.leftButtonTextColor}
                    borderColor={data.leftButtonBorderColor}
                    hoverColor={data.leftButtonHoverColor}
                    mr={data.rightButtonText ? ["0px", "0px", "24px", "24px"] : 0}
                  />
                )}

                {data.rightButtonText && (
                  <HeaderBannerButton
                    mt={["12px", "12px", "0px", "0px"]}
                    text={data.rightButtonText}
                    href={data.rightButtonHref}
                    backgroundColor={data.rightButtonBackgroundColor}
                    textColor={data.rightButtonTextColor}
                    borderColor={data.rightButtonBorderColor}
                    hoverColor={data.rightButtonHoverColor}
                  />
                )}
              </Flex>
            )}
          </Box>

          {data.foregroundImage && data.foregroundImage.url && (
            <Image
              ignoreFallback={true}
              zIndex={1}
              mt={["32px", "32px", "32px", 0]}
              ml={[0, 0, 0, "auto"]}
              mx={["auto", "auto", "auto", null]}
              width={["100%", "100%", "550px", "550px"]}
              height={[200, 300, "368px", "368px"]}
              src={data.foregroundImage.url}
              alt={data.foregroundImage.alt || ""}
              position={["relative", "relative", "relative"]}
              objectFit="contain"
            />
          )}
        </Box>
      </PageGridContainer>
    </Box>
  );
}

interface IHeaderBannerButtonProps {
  text: string | null | undefined;
  href: string | null | undefined;
  textColor: string | null | undefined;
  backgroundColor: string | null | undefined;
  borderColor: string | null | undefined;
  hoverColor: string | null | undefined;
  mr?: string[] | string | number | null | undefined;
  mt?: string[] | string | number | null | undefined;
}

function HeaderBannerButton({
  text,
  href,
  backgroundColor,
  textColor,
  borderColor,
  hoverColor,
  mr,
  mt,
}: IHeaderBannerButtonProps) {
  if (href && !href.startsWith("http")) {
    return (
      <Link href={href} passHref={true}>
        <AnchorButton
          variant="primary"
          bg={backgroundColor}
          color={textColor || "#ffffff"}
          borderColor={borderColor}
          _hover={{
            bg: hoverColor,
            borderColor: hoverColor,
          }}
          rel="noreferrer noopener"
          minWidth="250px"
          whiteSpace="pre-wrap"
          textAlign="center"
          mr={mr}
          mt={mt}
        >
          {text}
        </AnchorButton>
      </Link>
    );
  }

  return (
    <AnchorButton
      variant="primary"
      href={href || ""}
      bg={backgroundColor}
      color={textColor || "#ffffff"}
      borderColor={borderColor}
      _hover={{
        bg: hoverColor,
        borderColor: hoverColor,
      }}
      external="outside-redirect"
      rel="noreferrer noopener"
      minWidth="250px"
      whiteSpace="pre-wrap"
      textAlign="center"
      mr={mr}
      mt={mt}
      onClick={(event) => {
        if (!href) {
          event.preventDefault();
        }
      }}
    >
      {text}
    </AnchorButton>
  );
}
