import { useQuery } from "@apollo/client";
import { Anchor, Box } from "@govlaunch/web";
import Link from "next/link";
import React from "react";
import GetNewProjects from "../../project/graphql/GetNewProjects";
import {
  IGetNewProjectQuery,
  IGetNewProjectQueryVariables,
} from "../../project/graphql/__generated__/GetNewProjects.generated";
import { IBlockLayoutDataBase } from "./blockProps";
import CenteredLoadingSpinner from "./CenteredLoadingSpinner";

interface INewProjectsBlockProps {
  blockData: IBlockLayoutDataBase;
}

export default function NewProjectsBlock({ blockData }: INewProjectsBlockProps) {
  const { data, loading } = useQuery<IGetNewProjectQuery, IGetNewProjectQueryVariables>(GetNewProjects, {
    variables: {
      cursor: null,
      limit: blockData.itemLimit || 10,
    },
    fetchPolicy: "network-only",
  });

  if (!data || loading) {
    return <CenteredLoadingSpinner />;
  }

  const projects = data.getNewProjects?.items || [];

  return (
    <Box width="100%" height="100%">
      {projects.map((project) => (
        <div
          key={project._id}
          css={{
            "&:not(:first-of-type)": {
              marginTop: "20px",
            },
          }}
        >
          <ProjectRow project={project} />
        </div>
      ))}
    </Box>
  );
}

interface IProjectRowProps {
  project: NonNullable<NonNullable<IGetNewProjectQuery["getNewProjects"]>["items"]>[0];
}

function ProjectRow({ project }: IProjectRowProps) {
  const country = project.title.match(/([A-zÀ-ú-/&'., 0-9]+, (?:[A-Z]{2,3}))\s(.+)/);
  const title = country ? project.title.replace(country[1], "") : project.title;

  return (
    <Link href={`/projects/${project.slug}`} as={`/projects/${project.slug}`} passHref={true}>
      <Anchor width="100%" flexDirection="row" alignItems="start" cursor="pointer" color="inherit">
        <img
          src={`https://static.govlaunch.com/flags/squared/${project.countryCode || ""}.svg`}
          width="30px"
          alt={project.countryCode || ""}
          title={project.countryCode || ""}
          style={{
            border: 0,
            outline: "none",
            textDecoration: "none",
            margin: 0,
            padding: 0,
            marginTop: "6px",
            marginRight: "10px",
          }}
        />

        <Box>
          {country && country[1] && (
            <span
              style={{
                color: "#086bef",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
                letterSpacing: "normal",
              }}
            >
              {country[1]}
            </span>
          )}
          <span
            css={{
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            {title}
          </span>
        </Box>
      </Anchor>
    </Link>
  );
}
