import gql from "graphql-tag";

export default gql`
  query StoriesByIds($ids: [ObjectId!]!) {
    storiesByIds(ids: $ids) {
      _id
      slug
      title
      introduction
      medias {
        type
        url
        alt
      }
      tags {
        _id
        name
        slug
      }
    }
  }
`;
