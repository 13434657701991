import { useQuery } from "@apollo/client";
import { Anchor, Box, palette } from "@govlaunch/web";
import React from "react";
import { IExternalLink } from "~/types/types";
import GetExternalLinks from "../graphql/GetExternalLinks";
import {
  IGetExternalLinksQuery,
  IGetExternalLinksQueryVariables,
} from "../graphql/__generated__/GetExternalLinks.generated";
import { IBlockLayoutDataBase } from "./blockProps";
import CenteredLoadingSpinner from "./CenteredLoadingSpinner";

interface IExternalLinksBlockProps {
  blockData: IBlockLayoutDataBase;
}

export default function ExternalLinksBlock({ blockData }: IExternalLinksBlockProps) {
  const { data, loading } = useQuery<IGetExternalLinksQuery, IGetExternalLinksQueryVariables>(GetExternalLinks, {
    variables: {
      search: null,
      limit: blockData.itemLimit || 10,
      cursor: null,
    },
    fetchPolicy: "network-only",
  });

  if (!data || loading) {
    return <CenteredLoadingSpinner />;
  }

  const links = data.externalLinks?.items;

  return (
    <Box width="100%">
      {(links || []).map((link) => (
        <div
          key={`link_${link._id}`}
          css={{
            "&:not(:first-of-type)": {
              marginTop: "10px",
            },
          }}
        >
          <ExternalLinkRow link={link} />
        </div>
      ))}
    </Box>
  );
}

interface IExternalLinkRowProps {
  link: IExternalLink;
}

function ExternalLinkRow({ link }: IExternalLinkRowProps) {
  return (
    <Anchor href={link.url} external="outside-redirect">
      <Box lineHeight="24px" fontSize="16px" fontWeight={[400, 400, 600, 600]} color={palette.primary}>
        {link.title}{" "}
        <Box as="span" fontSize="12px" fontWeight={400} color="gray.900">
          {link.source}
        </Box>
      </Box>
    </Anchor>
  );
}
