import { Box } from "@govlaunch/web";
import React from "react";

interface IBlockLayoutContainerTitleProps {
  title: string;
}

export default function BlockLayoutContainerTitle({ title }: IBlockLayoutContainerTitleProps) {
  return (
    <Box width="100%" mb="20px" fontWeight={600} fontSize="24px" lineHeight="32px" color="#000000" tabIndex={0}>
      {title}
    </Box>
  );
}
