import React from "react";

export default function VendorsIcon(props: React.SVGProps<any>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="4" fill="#2F80ED" />
      <g clipPath="url(#clip0_15775_99906)">
        <path
          d="M16 6.8335C10.9374 6.8335 6.83337 10.9375 6.83337 16.0002C6.83337 21.0628 10.9374 25.1668 16 25.1668C21.0627 25.1668 25.1667 21.0628 25.1667 16.0002C25.1667 10.9375 21.0627 6.8335 16 6.8335ZM16.8367 21.3902V23.1662H15.127V21.5129C13.9266 21.4603 12.8046 21.1448 12.1385 20.7592L12.6601 18.7083C13.3963 19.1115 14.4283 19.4796 15.5676 19.4796C16.5668 19.4796 17.2493 19.0939 17.2493 18.3928C17.2493 17.7267 16.6879 17.306 15.3907 16.8678C13.5152 16.2368 12.2365 15.3604 12.2365 13.6601C12.2365 12.1176 13.3082 10.9082 15.1998 10.5401V8.87053H16.9094V10.4174C18.0735 10.47 18.8755 10.7154 19.4539 10.9958L18.947 12.9765C18.4913 12.7837 17.6857 12.3806 16.4236 12.3806C15.2843 12.3806 14.9165 12.8714 14.9165 13.3622C14.9165 13.9406 15.5302 14.3087 17.0201 14.8696C19.106 15.6058 19.952 16.5698 19.952 18.1474C19.9521 19.7074 18.8373 21.0396 16.8367 21.3902Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_15775_99906">
          <rect width="22" height="22" fill="white" transform="translate(5 5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
