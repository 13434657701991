import { Image } from "@govlaunch/web";
import React from "react";
import { IImageBlockData } from "./blockProps";

interface IImageBlockProps {
  blockData: IImageBlockData;
}

export default function ImageBlock({ blockData }: IImageBlockProps) {
  return <Image height="100%" width="100%" objectFit="cover" src={blockData.url} alt={blockData.alt} />;
}
