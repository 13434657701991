import { ILandingPageConfigBlock } from "~/types/types";

export function prepareGridData(blocks: ILandingPageConfigBlock[]) {
  return blocks.map((block) => {
    return {
      i: block.i,
      x: block.x,
      y: block.y,
      w: block.w,
      h: block.h,
      isBounded: block.isBounded || true,
      isDraggable: block.isDraggable || false,
      isResizable: block.isResizable || false,
      static: block.static || false,
      blockData: block.blockData,
    };
  });
}
