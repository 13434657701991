import gql from "graphql-tag";
import LandingPageEventFields from "./fragments/LandingPageEventFields";

export default gql`
  query GetUpcomingEvents($limit: Int = 20) {
    getUpcomingEvents(limit: $limit) {
      ...LandingPageEventFields
    }
  }

  ${LandingPageEventFields}
`;
