import { useQuery } from "@apollo/client";
import { Anchor, Avatar, Box, CountryFlag, Flex, palette, SimpleGrid, TCountryCode } from "@govlaunch/web";
import React, { useState } from "react";
import { useSelfie } from "~/components/auth/Auth";
import BookmarkIcon from "~/components/icons/BookmarkIcon";
import ToggleFollow from "~/lib/mutations/ToggleFollow";
import TopInnovatorGovernments from "../graphql/TopInnovatorGovernments";
import TrendingGovernments from "../graphql/TrendingGovernments";
import {
  ITopInnovatorGovernmentsQuery,
  ITopInnovatorGovernmentsQueryVariables,
} from "../graphql/__generated__/TopInnovatorGovernments.generated";
import {
  ITrendingGovernmentsQuery,
  ITrendingGovernmentsQueryVariables,
} from "../graphql/__generated__/TrendingGovernments.generated";
import FollowGovernmentIcon from "../icons/FollowGovernmentIcon";
import { IGovernmentsBlockData } from "./blockProps";
import CenteredLoadingSpinner from "./CenteredLoadingSpinner";
import Link from "next/link";

interface IGovernmentsBlockProps {
  blockData: IGovernmentsBlockData;
}

enum GovernmentsTab {
  TRENDING = "TRENDING",
  INNOVATORS = "INNOVATORS",
}

export default function GovernmentsBlock({ blockData }: IGovernmentsBlockProps) {
  const [tab, setTab] = useState<GovernmentsTab>(GovernmentsTab.TRENDING);

  return (
    <>
      <SimpleGrid
        width="100%"
        columns={2}
        fontSize="24px"
        fontWeight={600}
        lineHeight="32px"
        alignItems="end"
        justifyItems="center"
      >
        <Box
          width="100%"
          cursor="pointer"
          onClick={() => setTab(GovernmentsTab.TRENDING)}
          onKeyPress={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              setTab(GovernmentsTab.TRENDING);
              e.preventDefault();
            }
          }}
          pb={tab === GovernmentsTab.TRENDING ? "5px" : "6px"}
          borderBottom={tab === GovernmentsTab.TRENDING ? `2px solid ${palette.primary}` : "1px solid #ADB6C3"}
          textAlign="center"
          fontSize={["18px", "24px", "24px", "24px"]}
          tabIndex={0}
        >
          {blockData.title}
        </Box>
        <Box
          width="100%"
          cursor="pointer"
          onClick={() => setTab(GovernmentsTab.INNOVATORS)}
          onKeyPress={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              setTab(GovernmentsTab.INNOVATORS);
              e.preventDefault();
            }
          }}
          pb={tab === GovernmentsTab.INNOVATORS ? "5px" : "6px"}
          borderBottom={tab === GovernmentsTab.INNOVATORS ? `2px solid ${palette.primary}` : "1px solid #ADB6C3"}
          textAlign="center"
          fontSize={["18px", "24px", "24px", "24px"]}
          tabIndex={0}
        >
          {blockData.secondTitle}
        </Box>
      </SimpleGrid>
      <Box mt="40px" width="100%" px="12px">
        {tab === GovernmentsTab.TRENDING && <TrendingGovernmentsTab blockData={blockData} />}
        {tab === GovernmentsTab.INNOVATORS && <InnovatorGovernmentsTab blockData={blockData} />}
      </Box>
    </>
  );
}

function TrendingGovernmentsTab({ blockData }: IGovernmentsBlockProps) {
  const { data, loading } = useQuery<ITrendingGovernmentsQuery, ITrendingGovernmentsQueryVariables>(
    TrendingGovernments,
    {
      variables: {
        limit: blockData.itemLimit || 10,
      },
      fetchPolicy: "network-only",
    },
  );

  if (loading || !data) {
    return <CenteredLoadingSpinner />;
  }

  const governments = data && data.trendingGovernments ? data.trendingGovernments || [] : [];

  return (
    <SimpleGrid width="100%" height="100%" columns={1} alignItems="center" spacing="20px">
      {governments.map((government) => (
        <div key={government._id}>
          <GovernmentRow government={government} />
        </div>
      ))}
    </SimpleGrid>
  );
}

function InnovatorGovernmentsTab({ blockData }: IGovernmentsBlockProps) {
  const { data, loading } = useQuery<ITopInnovatorGovernmentsQuery, ITopInnovatorGovernmentsQueryVariables>(
    TopInnovatorGovernments,
    {
      variables: {
        limit: blockData.itemLimit || 10,
      },
      fetchPolicy: "network-only",
    },
  );

  if (loading || !data) {
    return <CenteredLoadingSpinner />;
  }

  const governments = data && data.topInnovatorGovernments ? data.topInnovatorGovernments || [] : [];

  return (
    <SimpleGrid width="100%" height="100%" columns={1} alignItems="center" spacing="20px">
      {governments.map((government) => (
        <div key={government._id}>
          <GovernmentRow government={government} />
        </div>
      ))}
    </SimpleGrid>
  );
}

interface IGovernmentRowProps {
  government: NonNullable<ITrendingGovernmentsQuery["trendingGovernments"]>[0];
}

function GovernmentRow({ government }: IGovernmentRowProps) {
  return (
    <SimpleGrid width="100%" columns={1} cursor="pointer">
      <Flex flexDirection="row" alignItems="center">
        <Link
          href={`/governments/${government.slug}`}
          as={`/governments/${government.slug}`}
          passHref={true}
          tabIndex={0}
        >
          <Anchor flexDirection="row" alignItems="center" color="inherit">
            <Avatar size="sm" name={government.name} src={government.logo} />
            <Flex flexDirection="column" ml="8px">
              <Box fontSize="16px" fontWeight={600} lineHeight="21px">
                {government.name}
              </Box>
              <Flex flexDirection="row" mt="4px">
                <CountryFlag width={20} height={12} countryCode={government.city.country as TCountryCode} />
                <Box fontSize="12px" fontWeight={400} lineHeight="16px" color="blue.600" ml="4px">
                  {getCountryFriendlyName(government.city.country)}
                </Box>
              </Flex>
            </Flex>
          </Anchor>
        </Link>

        <Flex flexDirection="row" alignItems="center" justifyContent="center" ml="auto">
          <GovernmentRowFollowButton government={government} />
        </Flex>
      </Flex>
    </SimpleGrid>
  );
}

function GovernmentRowFollowButton({ government }: IGovernmentRowProps) {
  const user = useSelfie();

  if (!user) {
    return (
      <Link href="/signup" passHref={true}>
        <div>
          <FollowGovernmentIcon cursor="pointer" />
        </div>
      </Link>
    );
  }

  return (
    <ToggleFollow
      targetId={government._id}
      entityType="Government"
      viewerIsFollowing={government.isFollowing as boolean}
    >
      {({ toggle }) => {
        if (!government.isFollowing) {
          return <FollowGovernmentIcon onClick={toggle} cursor="pointer" />;
        }

        return <BookmarkIcon height="24px" width="24px" fill={palette.primary} onClick={toggle} cursor="pointer" />;
      }}
    </ToggleFollow>
  );
}

function getCountryFriendlyName(country: string) {
  switch (country) {
    case "US":
      return "United States";
    case "CA":
      return "Canada";
    case "NZ":
      return "New Zealand";
    case "AU":
      return "Australia";
    case "ZA":
      return "South Africa";
    case "GB":
      return "Great Britain";
    case "IE":
      return "Ireland";
    case "FI":
      return "Finland";
    case "SE":
      return "Sweden";
    case "BR":
      return "Brazil";
    case "MX":
      return "Mexico";
    case "AR":
      return "Argentina";
    case "DE":
      return "Germany";
    case "DK":
      return "Denmark";
    case "IT":
      return "Italy";
    case "NL":
      return "Netherlands";
    case "AT":
      return "Austria";
    case "BE":
      return "Belgium";
    case "LI":
      return "Lithuania";
    case "ES":
      return "Spain";
    case "FR":
      return "France";
    case "CH":
      return "Switzerland";
  }
}
