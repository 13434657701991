import React from "react";

interface ICollectionIconProps extends React.SVGProps<any> {
  fillColor?: string;
}

export default function CollectionIcon({ fillColor, ...props }: ICollectionIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.30769 2C3.67521 2 3.07368 2.2244 2.64904 2.64904C2.2244 3.07368 2 3.67521 2 4.30769V7.38462V10.4615V19.6923C2 20.3248 2.2244 20.9263 2.64904 21.351C3.07368 21.7756 3.67521 22 4.30769 22H7H19.6923H20C21.1046 22 22 21.1046 22 20V19.6923V11.2308V10.4615V10V8.92308V7.38462C22 6.75214 21.7756 6.1506 21.351 5.72596C20.9263 5.30132 20.3248 5.07692 19.6923 5.07692H11.2308V4.30769C11.2308 3.67521 11.0064 3.07368 10.5817 2.64904C10.1571 2.2244 9.55556 2 8.92308 2H4.30769ZM20 8C20.1589 8 20.3134 8.01852 20.4615 8.05352V7.38462C20.4615 7.09402 20.377 6.92752 20.2632 6.8137C20.1494 6.69988 19.9829 6.61538 19.6923 6.61538H11.2308C10.8291 6.61538 10.418 6.46971 10.128 6.17969C9.83798 5.88966 9.69231 5.47863 9.69231 5.07692V4.30769C9.69231 4.01709 9.60781 3.8506 9.49399 3.73678C9.38017 3.62296 9.21368 3.53846 8.92308 3.53846H4.30769C4.01709 3.53846 3.8506 3.62296 3.73678 3.73678C3.62296 3.8506 3.53846 4.01709 3.53846 4.30769V7.38462V10.4615V19.6923C3.53846 19.9829 3.62296 20.1494 3.73678 20.2632C3.8506 20.377 4.01709 20.4615 4.30769 20.4615C4.59829 20.4615 4.76478 20.377 4.87861 20.2632C4.92616 20.2157 4.9686 20.1589 5.00189 20.0878C5.00064 20.0587 5 20.0294 5 20V10C5 8.89543 5.89543 8 7 8H20ZM14.9548 13.4977L13.5 11L12.0452 13.4977L9.22025 14.1094L11.1461 16.2648L10.855 19.1406L13.5 17.975L16.145 19.1406L15.8539 16.2648L17.7798 14.1094L14.9548 13.4977Z"
        fill={fillColor || "#086BE5"}
      />
    </svg>
  );
}
