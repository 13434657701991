import { useQuery } from "@apollo/client";
import { Anchor, Box, Flex, Image, SimpleGrid } from "@govlaunch/web";
import Link from "next/link";
import React from "react";
import { IMediaType } from "~/types/types";
import GetStoriesByIds from "../graphql/GetStoriesByIds";
import { IStoriesByIdsQuery, IStoriesByIdsQueryVariables } from "../graphql/__generated__/GetStoriesByIds.generated";
import { IFeaturedStoriesData } from "./blockProps";
import CenteredLoadingSpinner from "./CenteredLoadingSpinner";

interface IFeaturedStoriesBlockProps {
  blockData: IFeaturedStoriesData;
}

export default function FeaturedStoriesBlock({ blockData }: IFeaturedStoriesBlockProps) {
  const { data, loading } = useQuery<IStoriesByIdsQuery, IStoriesByIdsQueryVariables>(GetStoriesByIds, {
    variables: {
      ids: blockData.storyIds,
    },
  });

  if (!data || loading) {
    return <CenteredLoadingSpinner />;
  }

  const stories = data.storiesByIds || [];

  return (
    <SimpleGrid width="100%" columns={1} spacing="40px">
      {stories.map((story, index) => (
        <div key={story._id}>
          <StoryRow story={story} isLast={index === stories.length - 1} />
        </div>
      ))}
    </SimpleGrid>
  );
}

type TStory = NonNullable<IStoriesByIdsQuery["storiesByIds"]>[0];

interface IStoryRowProps {
  story: TStory;
  isLast?: boolean;
}

function StoryRow({ story, isLast = false }: IStoryRowProps) {
  const image = (story.medias || []).find((media) => media.type?.toUpperCase() === IMediaType.Image);
  const tags = story.tags || [];

  return (
    <Link href={`/stories/${story.slug}`} as={`/stories/${story.slug}`} passHref={true}>
      <Anchor
        tabIndex={0}
        width="100%"
        flexDirection={["column", "column", "row", "row"]}
        borderBottom={isLast ? undefined : "1px solid"}
        borderColor={isLast ? undefined : "gray.100"}
        pb="40px"
        cursor="pointer"
        color="inherit"
      >
        <Image
          display={["block", "block", "none", "none"]}
          maxWidth={["100%", "100%", "192px", "192px"]}
          maxHeight={["120px", "120px", "160px", "160px"]}
          objectFit="cover"
          src={image ? image.url : `${getGovlaunchBaseUrl()}/static/story/story-card-placeholder.png`}
          alt={image ? image.alt || "" : ""}
        />
        <Flex flexDirection="column" mt={["12px", "12px", "0px", "0px"]}>
          <Box
            mr={["0px", "0px", "12px", "12px"]}
            fontWeight={[700, 700, 600, 600]}
            fontSize={["18px", "18px", "24px", "24px"]}
            lineHeight={["24px", "24px", "32px", "32px"]}
            color="blue.700"
          >
            {story.title}
          </Box>
          <Box
            display={["none", "none", "block", "block"]}
            mr={["0px", "0px", "12px", "12px"]}
            fontWeight={400}
            fontSize="14px"
            lineHeight="20px"
            mt="6px"
          >
            {story.introduction}
          </Box>
          <Flex flexDirection="row" mt={["12px", "12px", "30px", "30px"]} alignItems="center">
            {tags.slice(0, 2).map((tag) => (
              <div
                key={tag._id}
                css={{
                  "&:not(:first-of-type)": {
                    marginLeft: "8px",
                  },
                }}
              >
                <StoryRowTag name={tag.name} />
              </div>
            ))}
            {tags.length > 2 && (
              <Box fontWeight={900} fontSize="12px" lineHeight="19px" color="blue.700" ml="12px" py="4px">
                +{tags.length - 2}
              </Box>
            )}
          </Flex>
        </Flex>
        <Image
          ml="auto"
          display={["none", "none", "block", "block"]}
          maxWidth={["84px", "84px", "192px", "192px"]}
          maxHeight={["84px", "84px", "160px", "160px"]}
          objectFit="cover"
          src={image ? image.url : `${getGovlaunchBaseUrl()}/static/story/story-card-placeholder.png`}
          alt={image ? image.alt || "" : ""}
        />
      </Anchor>
    </Link>
  );
}

interface IStoryRowTagProps {
  name: string;
}

function StoryRowTag({ name }: IStoryRowTagProps) {
  return (
    <Box
      fontWeight={400}
      fontSize="12px"
      lineHeight="16px"
      color="gray.700"
      px="8px"
      py="4px"
      border="1px solid"
      borderColor="gray.300"
      borderRadius={2}
      textTransform="uppercase"
    >
      {name}
    </Box>
  );
}

function getGovlaunchBaseUrl() {
  const environment = process.env.GOVLAUNCH_ENV;

  if (environment === "qa") {
    return `https://qa.govlaunch.com`;
  } else if (environment === "production") {
    return `https://govlaunch.com`;
  }

  return `http://localhost:3000`;
}
