import gql from "graphql-tag";

export default gql`
  query GetGovlaunchStatsCount($settings: GovlaunchStatsCountInput!) {
    govlaunchStatsCount(settings: $settings) {
      projectsCount
      productsCount
      governmentsCount
      storiesCount
      vendorsCount
    }
  }
`;
