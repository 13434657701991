import React from "react";
import BackToTop from "~/components/qol/BackToTop";
import LandingPageInner from "~/src/components/landingPage/LandingPageInner";
import NavbarLayout from "~/src/components/navbar/NavbarLayout";

export default function LandingPage() {
  return (
    <NavbarLayout
      navbarProps={{
        mb: 0,
      }}
      showBanners={false}
    >
      <LandingPageInner />
      <BackToTop />
    </NavbarLayout>
  );
}
