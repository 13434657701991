import gql from "graphql-tag";

export default gql`
  query TrendingCollections($limit: Int = 10) {
    trendingCollections(limit: $limit) {
      _id
      slug
      name
      contentCounters {
        productsCount
        projectsCount
        storiesCount
      }
    }
  }
`;
