import { useQuery } from "@apollo/client";
import { Anchor, Box, SimpleGrid, SvgBox } from "@govlaunch/web";
import Link from "next/link";
import React, { ReactElement } from "react";
import { IStatCountersBlockData } from "~/types/types";
import GetGovlaunchStatsCount from "../graphql/GetGovlaunchStatsCount";
import {
  IGetGovlaunchStatsCountQuery,
  IGetGovlaunchStatsCountQueryVariables,
} from "../graphql/__generated__/GetGovlaunchStatsCount.generated";
import GovernmentIcon from "../icons/GovernmentIcon";
import ProductIcon from "../icons/ProductIcon";
import ProjectIcon from "../icons/ProjectIcon";
import StoriesIcon from "../icons/StoriesIcon";
import VendorsIcon from "../icons/VendorsIcon";
import CenteredLoadingSpinner from "./CenteredLoadingSpinner";

interface IStatCountersBlockProps {
  blockData: IStatCountersBlockData;
}

export default function StatCountersBlock({ blockData }: IStatCountersBlockProps) {
  const { data, loading } = useQuery<IGetGovlaunchStatsCountQuery, IGetGovlaunchStatsCountQueryVariables>(
    GetGovlaunchStatsCount,
    {
      variables: {
        settings: {
          countProjects: blockData.showProjects,
          countProducts: blockData.showProducts,
          countGovernments: blockData.showGovernments,
          countStories: blockData.showStories,
          countVendors: blockData.showVendors,
        },
      },
    },
  );

  if (!data || loading) {
    return <CenteredLoadingSpinner />;
  }

  return (
    <StatCountersContainer>
      {blockData.showProjects && (
        <StatCountersWidget
          count={data.govlaunchStatsCount.projectsCount}
          countColor={blockData.countersColor}
          label="Projects"
          labelColor={blockData.labelsColor}
          widgetColor={blockData.widgetColor}
          link="/projects"
          icon={<ProjectIcon />}
        />
      )}
      {blockData.showGovernments && (
        <StatCountersWidget
          count={data.govlaunchStatsCount.governmentsCount}
          countColor={blockData.countersColor}
          label="Governments"
          labelColor={blockData.labelsColor}
          widgetColor={blockData.widgetColor}
          link="/governments"
          icon={<GovernmentIcon />}
        />
      )}
      {blockData.showProducts && (
        <StatCountersWidget
          count={data.govlaunchStatsCount.productsCount}
          countColor={blockData.countersColor}
          label="Products"
          labelColor={blockData.labelsColor}
          widgetColor={blockData.widgetColor}
          link="/products"
          icon={<ProductIcon />}
        />
      )}
      {blockData.showStories && (
        <StatCountersWidget
          count={data.govlaunchStatsCount.storiesCount}
          countColor={blockData.countersColor}
          label="Stories"
          labelColor={blockData.labelsColor}
          widgetColor={blockData.widgetColor}
          link="/stories"
          icon={<StoriesIcon />}
        />
      )}
      {blockData.showVendors && (
        <StatCountersWidget
          count={data.govlaunchStatsCount.vendorsCount}
          countColor={blockData.countersColor}
          label="Vendors"
          labelColor={blockData.labelsColor}
          widgetColor={blockData.widgetColor}
          link="/vendors"
          icon={<VendorsIcon />}
        />
      )}
    </StatCountersContainer>
  );
}

interface IStatCountersContainerProps {
  children: any;
}

function StatCountersContainer({ children }: IStatCountersContainerProps) {
  return (
    <SimpleGrid width="100%" height="100%" columns={[1, 1, 3, 3]} spacing={["20px", "20px", "20px", "20px"]}>
      {children}
    </SimpleGrid>
  );
}

interface IStatCountersWidgetProps {
  count: number;
  countColor: string;
  label: string;
  labelColor: string;
  widgetColor: string;
  link: string;
  icon?: ReactElement | null;
}

function StatCountersWidget({
  count,
  countColor,
  label,
  labelColor,
  widgetColor,
  link,
  icon,
}: IStatCountersWidgetProps) {
  return (
    <Link href={link} as={link} passHref={true}>
      <Anchor
        tabIndex={0}
        width={["100%", "100%", "100%", "100%"]}
        height={["100%", "100%", "150px", "150px"]}
        background={widgetColor}
        border="1px solid"
        borderColor="gray.300"
        borderRadius="4px"
        position="relative"
        cursor="pointer"
      >
        {icon && (
          <Box position="absolute" top={["4px", "4px", "8px"]} left={["4px", "4px", "8px"]}>
            {icon}
          </Box>
        )}

        <SvgBox
          width="9px"
          height="22px"
          viewBox="0 0 9 22"
          fill="none"
          position="absolute"
          right={2}
          top={["50%", "50%", "42.5%"]}
          transform="translateY(-50%)"
        >
          <path
            d="M1.88867 19.75L6.77756 11.2244L1.88867 2.25"
            stroke="#086BE5"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </SvgBox>
        <SimpleGrid
          width="100%"
          height="100%"
          columns={[2, 2, 1, 1]}
          alignItems={["center", "center", "center", "center"]}
          justifyContent={["center", "center", "center", "center"]}
          pl={["72px", "72px", "0px", "0px"]}
          py={["0px", "0px", "40px", "40px"]}
        >
          <Box
            fontWeight={700}
            fontSize="32px"
            lineHeight="40px"
            color={countColor}
            textAlign={["left", "left", "center", "center"]}
          >
            {count}
          </Box>
          <Box
            fontWeight={400}
            fontSize="16px"
            lineHeight="21px"
            color={labelColor}
            textAlign={["left", "left", "center", "center"]}
          >
            {label}
          </Box>
        </SimpleGrid>
      </Anchor>
    </Link>
  );
}
