import { IHeaderBannerContent } from "~/types/types";

export function headerBannerExists(headerBannerData: IHeaderBannerContent | null | undefined) {
  if (!headerBannerData) {
    return false;
  }

  if (
    headerBannerData.title ||
    headerBannerData.subtitle ||
    headerBannerData.backgroundImage?.url ||
    headerBannerData.foregroundImage?.url
  ) {
    return true;
  }

  return false;
}
