import { Mutation } from "@apollo/client/react/components";
import React from "react";
import getTargetType from "~/lib/mutations/getTargetType";
import ToggleFollowMutation from "~/lib/mutations/ToggleFollowMutation";
import { IToggleFollowMutation, IToggleFollowMutationVariables } from "~/lib/mutations/__generated__/ToggleFollowMutation.generated";
import { useSelfie } from "~/components/auth/Auth";
import { refetchQueries } from "~/lib/apollo/RefetchQueriesFixLink";

interface IToggleFollowProps {
  entityType: "Government" | "Company" | "VendorUser" | "GovernmentUser" | "PendingUser";
  targetId: string;
  viewerIsFollowing: boolean;
  children: (toggle: IToggle) => any;
}

interface IToggle {
  toggle: () => any;
}

const ToggleFollow: React.FunctionComponent<IToggleFollowProps> = ({
  entityType,
  targetId,
  viewerIsFollowing,
  children,
}) => {
  const user = useSelfie();

  if (!user) {
    return null;
  }

  return (
    <Mutation<IToggleFollowMutation, IToggleFollowMutationVariables>
      mutation={ToggleFollowMutation}
      variables={{
        targetType: getTargetType(entityType) as any,
        targetId: targetId,
      }}
      refetchQueries={refetchQueries(["GetProfile"])}
    >
      {(toggleFollow) =>
        children({
          toggle: () => {
            toggleFollow({
              optimisticResponse: {
                __typename: "Mutation",
                toggleFollow: {
                  __typename: entityType as any,
                  _id: targetId,
                  isFollowing: !viewerIsFollowing,
                },
              },
            });
          },
        })
      }
    </Mutation>
  );
};

export default ToggleFollow;
