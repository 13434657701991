import { useQuery } from "@apollo/client";
import { Anchor, Box, Flex, palette, SimpleGrid } from "@govlaunch/web";
import React, { useState } from "react";
import TopCollections from "../graphql/TopCollections";
import TrendingCollections from "../graphql/TrendingCollections";
import { ITopCollectionsQuery, ITopCollectionsQueryVariables } from "../graphql/__generated__/TopCollections.generated";
import {
  ITrendingCollectionsQuery,
  ITrendingCollectionsQueryVariables,
} from "../graphql/__generated__/TrendingCollections.generated";
import CollectionIcon from "../icons/CollectionIcon";
import { ICollectionsBlockData } from "./blockProps";
import CenteredLoadingSpinner from "./CenteredLoadingSpinner";
import Link from "next/link";

interface ICollectionsBlockProps {
  blockData: ICollectionsBlockData;
}

enum CollectionsTab {
  TRENDING = "TRENDING",
  TOP = "TOP",
}

export default function CollectionsBlock({ blockData }: ICollectionsBlockProps) {
  const [tab, setTab] = useState<CollectionsTab>(CollectionsTab.TRENDING);

  return (
    <>
      <SimpleGrid
        width="100%"
        columns={2}
        fontSize="24px"
        fontWeight={600}
        lineHeight="32px"
        alignItems="end"
        justifyItems="center"
      >
        <Box
          width="100%"
          cursor="pointer"
          onClick={() => setTab(CollectionsTab.TRENDING)}
          onKeyPress={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              setTab(CollectionsTab.TRENDING);
              e.preventDefault();
            }
          }}
          pb={tab === CollectionsTab.TRENDING ? "5px" : "6px"}
          borderBottom={tab === CollectionsTab.TRENDING ? `2px solid ${palette.primary}` : "1px solid #ADB6C3"}
          textAlign="center"
          fontSize={["18px", "24px", "24px", "24px"]}
          tabIndex={0}
        >
          {blockData.title}
        </Box>
        <Box
          width="100%"
          cursor="pointer"
          onClick={() => setTab(CollectionsTab.TOP)}
          onKeyPress={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              setTab(CollectionsTab.TOP);
              e.preventDefault();
            }
          }}
          pb={tab === CollectionsTab.TOP ? "5px" : "6px"}
          borderBottom={tab === CollectionsTab.TOP ? `2px solid ${palette.primary}` : "1px solid #ADB6C3"}
          textAlign="center"
          fontSize={["18px", "24px", "24px", "24px"]}
          tabIndex={0}
        >
          {blockData.secondTitle}
        </Box>
      </SimpleGrid>
      <Box mt="40px" width="100%" px="12px">
        {tab === CollectionsTab.TRENDING && <TrendingCollectionsTab blockData={blockData} />}
        {tab === CollectionsTab.TOP && <TopCollectionsTab blockData={blockData} />}
      </Box>
    </>
  );
}

function TrendingCollectionsTab({ blockData }: ICollectionsBlockProps) {
  const { data, loading } = useQuery<ITrendingCollectionsQuery, ITrendingCollectionsQueryVariables>(
    TrendingCollections,
    {
      variables: {
        limit: blockData.itemLimit || 10,
      },
      fetchPolicy: "network-only",
    },
  );

  if (loading || !data) {
    return <CenteredLoadingSpinner />;
  }

  const collections = data && data.trendingCollections ? data.trendingCollections || [] : [];

  if (blockData.displayAsTags) {
    return <CollectionsAsTags collections={collections} />;
  }

  return <CollectionsAsRows collections={collections} />;
}

function TopCollectionsTab({ blockData }: ICollectionsBlockProps) {
  const { data, loading } = useQuery<ITopCollectionsQuery, ITopCollectionsQueryVariables>(TopCollections, {
    variables: {
      limit: blockData.itemLimit || 10,
    },
    fetchPolicy: "network-only",
  });

  if (loading || !data) {
    return <CenteredLoadingSpinner />;
  }

  const collections = data && data.topCollections ? data.topCollections || [] : [];

  if (blockData.displayAsTags) {
    return <CollectionsAsTags collections={collections} />;
  }

  return <CollectionsAsRows collections={collections} />;
}

interface ICollectionsAsTagsProps {
  collections: ITrendingCollectionsQuery["trendingCollections"];
}

function CollectionsAsTags({ collections }: ICollectionsAsTagsProps) {
  return (
    <Flex flexDirection="row" flexWrap="wrap">
      {collections.map((collection) => (
        <div key={collection._id}>
          <CollectionTag collection={collection} />
        </div>
      ))}
    </Flex>
  );
}

interface ICollectionTagProps {
  collection: ITrendingCollectionsQuery["trendingCollections"][0];
}

function CollectionTag({ collection }: ICollectionTagProps) {
  const itemCount =
    collection.contentCounters.productsCount +
    collection.contentCounters.projectsCount +
    collection.contentCounters.storiesCount;

  return (
    <Link href={`/collections/${collection.slug}`} as={`/collections/${collection.slug}`} passHref={true}>
      <Anchor
        tabIndex={0}
        border="1px solid #ADB6C3"
        borderRadius="2px"
        flexDirection="row"
        alignItems="center"
        justifyContent="left"
        px="8px"
        py="4px"
        mb="8px"
        mr="8px"
        cursor="pointer"
        color="inherit"
      >
        <CollectionIcon fillColor="#2f3844" width="18px" height="18px" />
        <Box ml="12px" fontWeight={400} fontSize="16px" color="#20252d">
          {collection.name}
        </Box>
        <Box fontSize="14px" fontWeight={400} color="gray.300" ml="16px">
          {itemCount > 1 ? `${itemCount} items` : `${itemCount} item`}
        </Box>
      </Anchor>
    </Link>
  );
}

function CollectionsAsRows({ collections }: ICollectionsAsTagsProps) {
  return (
    <SimpleGrid columns={1} spacing="20px">
      {collections.map((collection) => (
        <div key={collection._id}>
          <CollectionRow collection={collection} />
        </div>
      ))}
    </SimpleGrid>
  );
}

function CollectionRow({ collection }: ICollectionTagProps) {
  const itemCount =
    collection.contentCounters.productsCount +
    collection.contentCounters.projectsCount +
    collection.contentCounters.storiesCount;

  return (
    <Link href={`/collections/${collection.slug}`} as={`/collections/${collection.slug}`} passHref={true}>
      <Flex width="100%" flexDirection="row" alignItems="center" cursor="pointer" tabIndex={0}>
        <CollectionIcon fillColor="#2f3844" width="18px" height="18px" />
        <Box width="300px" ml="16px" fontWeight={600} fontSize="16px">
          {collection.name}
        </Box>
        <Box minWidth="100px" fontWeight={400} fontSize="16px" textAlign="right">
          {itemCount > 1 ? `${itemCount} items` : `${itemCount} item`}
        </Box>
      </Flex>
    </Link>
  );
}
