import { gql } from "@apollo/client";
import LandingPageConfigFields from "./fragments/LandingPageConfigFields";

export default gql`
  query GetActiveLandingPageConfig($id: ObjectId) {
    getActiveLandingPageConfig(id: $id) {
      ...LandingPageConfigFields
    }
  }

  ${LandingPageConfigFields}
`;
