import React from "react";

export default function StoriesIcon(props: React.SVGProps<any>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="4" fill="#2F80ED" />
      <path
        d="M10.1666 10.1665V25.1665H20.1666L25.1666 20.1665V10.1665H10.1666ZM20.1666 22.8082V20.1665H22.8083L20.1666 22.8082ZM23.5 18.4998H18.5V23.4998H11.8333V11.8332H23.5V18.4998Z"
        fill="white"
      />
      <path d="M15.1667 14.3335H13.5V16.0002H15.1667V14.3335Z" fill="white" />
      <path d="M21 14.3335H16.8334V16.0002H21V14.3335Z" fill="white" />
      <path d="M16.8333 17.6665H13.5V19.3332H16.8333V17.6665Z" fill="white" />
      <path
        d="M6.83337 6.8335V18.5252V20.1668H8.50004H10.1667V18.5252H8.50004V8.50016H17.6667V10.1668H19.3334V6.8335H6.83337Z"
        fill="white"
      />
    </svg>
  );
}
