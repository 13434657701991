import { Flex } from "@govlaunch/web";
import React from "react";
import { IBlockData } from "~/types/types";

interface IBlockLayoutContainerProps {
  blockData: IBlockData;
  children: any;
}

export default function BlockLayoutContainer({ blockData, children }: IBlockLayoutContainerProps) {
  return (
    <Flex
      px="20px"
      width="100%"
      height="100%"
      flexDirection="column"
      alignItems="center"
      overflow="auto"
      backgroundColor={blockData.backgroundColor || undefined}
      paddingTop={`${blockData.paddingTop || 0}px`}
      paddingBottom={`${blockData.paddingBottom || 0}px`}
      paddingRight={`${blockData.paddingRight || 0}px`}
      paddingLeft={`${blockData.paddingLeft || 0}px`}
      borderRadius="5px"
    >
      {children}
    </Flex>
  );
}
