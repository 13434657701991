import React, { useEffect, useState } from "react";
import { IBlockLayoutType, ILandingPageConfigBlock } from "~/types/types";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Flex, PageGridContainer } from "@govlaunch/web";
import BlockLayout from "./BlockLayout";
import { prepareGridData } from "./prepareGridData";
import useIsMobile from "~/lib/hooks/useIsMobile";
import { sortBy } from "lodash";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface ILandingPageGridProps {
  blocks: ILandingPageConfigBlock[];
  allowOverlap?: boolean | null;
}

export default function LandingPageGrid({ blocks, allowOverlap }: ILandingPageGridProps) {
  const isMobile = useIsMobile();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const lgGridData = prepareGridData(blocks || []);
  const sortByKeys = lgGridData
    .sort((a, b) => {
      return a.x - b.x;
    })
    .sort((a, b) => {
      if (a.x === b.x) {
        return a.y - b.y;
      }
      return 0;
    })
    .map((item) => {
      return item.i;
    });

  return (
    <PageGridContainer mt={isMobile ? "40px" : "30px"}>
      {!isMobile && isLoaded && (
        <ResponsiveGridLayout
          className="layout"
          layouts={{
            lg: lgGridData,
          }}
          breakpoints={{
            lg: 1200,
            md: 960,
            sm: 640,
            xs: 480,
            xxs: 240,
          }}
          cols={{
            lg: 16,
            md: 16,
            sm: 16,
            xs: 16,
            xxs: 16,
          }}
          rowHeight={8} //px
          margin={[8, 8]}
          isDraggable={false}
          isResizable={false}
          compactType={null}
          allowOverlap={allowOverlap || false}
        >
          {sortBy(blocks || [], (block) => {
            return sortByKeys.indexOf(block.i);
          }).map((block) => (
            <div key={block.i}>
              <BlockLayout block={block} />
            </div>
          ))}
        </ResponsiveGridLayout>
      )}
      {isMobile && (
        <Flex direction="column" alignItems="center" width="100%" mb="20px">
          {sortBy(bringStatCountersOnTop(blocks || []), (block) => {
            return sortByKeys.indexOf(block.i);
          }).map((block) => (
            <div
              key={block.i}
              css={{
                width: "100%",
                "&:not(:first-of-type)": {
                  marginTop: "40px",
                },
              }}
            >
              <BlockLayout block={block} />
            </div>
          ))}
        </Flex>
      )}
    </PageGridContainer>
  );
}

function bringStatCountersOnTop(blocks: ILandingPageConfigBlock[]) {
  return [...blocks].sort((block1, block2) => {
    if (
      block1.blockData.type !== IBlockLayoutType.StatCounters &&
      block2.blockData.type === IBlockLayoutType.StatCounters
    ) {
      return 1;
    }

    if (
      block1.blockData.type === IBlockLayoutType.StatCounters &&
      block2.blockData.type !== IBlockLayoutType.StatCounters
    ) {
      return -1;
    }

    return 0;
  });
}
