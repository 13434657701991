import React from "react";
import {
  IBlockLayoutType,
  ICollectionsBlockData,
  IFeaturedStoriesBlockData,
  IGovernmentsBlockData,
  IImageBlockData,
  IImageLinkBlockData,
  ILandingPageConfigBlock,
  IPodcastBlockData,
  IStatCountersBlockData,
} from "~/types/types";
import BlockLayoutContainer from "./BlockLayoutContainer";
import BlockLayoutContainerTitle from "./BlockLayoutContainerTitle";
import AdvertisementBlock from "./blocks/AdvertisementBlock";
import { IBlockLayoutDataBase, TBlockLayoutData } from "./blocks/blockProps";
import CollectionsBlock from "./blocks/CollectionsBlock";
import EventsBlock from "./blocks/EventsBlock";
import ExternalLinksBlock from "./blocks/ExternalLinksBlock";
import FeaturedStoriesBlock from "./blocks/FeaturedStoriesBlock";
import GovernmentsBlock from "./blocks/GovernmentsBlock";
import ImageBlock from "./blocks/ImageBlock";
import NewProjectsBlock from "./blocks/NewProjectsBlock";
import PodcastBlock from "./blocks/PodcastBlock";
import StatCountersBlock from "./blocks/StatCountersBlock";

interface IBlockLayoutProps {
  block: ILandingPageConfigBlock;
}

export function BlockLayout({ block }: IBlockLayoutProps) {
  return (
    <BlockLayoutContainer blockData={block.blockData}>
      {isTitleVisible(block.blockData as TBlockLayoutData) && (
        <BlockLayoutContainerTitle title={block.blockData.title} />
      )}

      {block.blockData.type === IBlockLayoutType.ExternalLinks && (
        <ExternalLinksBlock blockData={block.blockData as IBlockLayoutDataBase} />
      )}
      {block.blockData.type === IBlockLayoutType.Podcast && (
        <PodcastBlock blockData={block.blockData as IPodcastBlockData} />
      )}
      {block.blockData.type === IBlockLayoutType.StatCounters && (
        <StatCountersBlock blockData={block.blockData as IStatCountersBlockData} />
      )}
      {block.blockData.type === IBlockLayoutType.NewProjects && (
        <NewProjectsBlock blockData={block.blockData as IBlockLayoutDataBase} />
      )}
      {block.blockData.type === IBlockLayoutType.Image && <ImageBlock blockData={block.blockData as IImageBlockData} />}
      {block.blockData.type === IBlockLayoutType.Advertisement && (
        <AdvertisementBlock blockData={block.blockData as IImageLinkBlockData} />
      )}
      {block.blockData.type === IBlockLayoutType.FeaturedStories &&
        (block.blockData as IFeaturedStoriesBlockData).storyIds.length > 0 && (
          <FeaturedStoriesBlock blockData={block.blockData as IFeaturedStoriesBlockData} />
        )}
      {block.blockData.type === IBlockLayoutType.TrendingGovernments && (
        <GovernmentsBlock blockData={block.blockData as IGovernmentsBlockData} />
      )}
      {block.blockData.type === IBlockLayoutType.TrendingCollections && (
        <CollectionsBlock blockData={block.blockData as ICollectionsBlockData} />
      )}
      {block.blockData.type === IBlockLayoutType.Events && (
        <EventsBlock blockData={block.blockData as IBlockLayoutDataBase} />
      )}
    </BlockLayoutContainer>
  );
}

function isTitleVisible(blockData: TBlockLayoutData) {
  if (
    blockData.type === IBlockLayoutType.TrendingGovernments ||
    blockData.type === IBlockLayoutType.TrendingCollections
  ) {
    return false;
  }

  return blockData.titleVisible;
}

export default React.memo(BlockLayout);
