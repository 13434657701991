import { gql } from "@apollo/client";

export default gql`
  mutation ToggleFollow($targetType: FollowType!, $targetId: ObjectId!) {
    toggleFollow(targetType: $targetType, targetId: $targetId) {
      __typename
      ... on Government {
        _id
        isFollowing
      }
      ... on VendorUser {
        _id
        isFollowing
      }
      ... on GovernmentUser {
        _id
        isFollowing
      }
      ... on PendingUser {
        _id
        isFollowing
      }
    }
  }
`;
