import { Flex, LoadingSpinner } from "@govlaunch/web";
import React from "react";

export default function CenteredLoadingSpinner() {
  return (
    <Flex width="100%" height="100%" flexDirection="column" alignItems="center" justifyContent="center">
      <LoadingSpinner />
    </Flex>
  );
}
