import gql from "graphql-tag";

export default gql`
  query GetExternalLinks($search: String, $limit: Int = 20, $cursor: Cursor) {
    externalLinks(search: $search, limit: $limit, cursor: $cursor) {
      items {
        _id
        title
        url
        source
        createdAt
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }
`;
