import React from "react";

export default function ProductIcon(props: React.SVGProps<any>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="4" fill="#2F80ED" />
      <path
        d="M23.3333 14.1668V23.3335H8.66665V14.1668H23.3333ZM25.1666 12.3335H6.83331V25.1668H25.1666V12.3335Z"
        fill="white"
      />
      <path d="M23.3334 9.5835H8.66669V11.4168H23.3334V9.5835Z" fill="white" />
      <path d="M21.5 6.8335H10.5V8.66683H21.5V6.8335Z" fill="white" />
    </svg>
  );
}
