import { Anchor, Image } from "@govlaunch/web";
import React from "react";
import { IImageLinkBlockData } from "./blockProps";

interface IAdvertisementBlockProps {
  blockData: IImageLinkBlockData;
}

export default function AdvertisementBlock({ blockData }: IAdvertisementBlockProps) {
  return (
    <Anchor height="100%" width="100%" href={blockData.href} external="outside-redirect">
      <Image height="100%" width="100%" objectFit="cover" src={blockData.url} alt={blockData.alt} />
    </Anchor>
  );
}
